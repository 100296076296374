<template>
  <div>
    <Navbar v-if="$store.getters['account/isLoggedIn']"/>
    <Content v-bind:class="{ 'mt-10' : $store.getters['account/isLoggedIn'] }"/>
    <!-- <notifications/> -->
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Content from './Content'

export default {
  name: 'general-screen-dashboard',
  components: {
    Navbar,
    Content
  }
}
</script>
